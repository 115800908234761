<template>
	<div class="container">
		<div class="setDownPayment_box">
			<a-row>
				<a-col :span="10">
					<a-card title="收据信息" :bordered="false">
						<a-form :layout="'horizontal'" :model="invoiceInfo" labelAlign="right">
							<a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="收据名称" :colon="false">
								<a-input placeholder="请输入收据名称" v-model="invoiceInfo.name" />
							</a-form-item>
							<!-- 订餐定金专用收据(凭单消费) -->
							<a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="收据说明" :colon="false">
								<a-input placeholder="请输入收据说明" v-model="invoiceInfo.notes" />
								<!-- 此定金专用收据仅限当餐抵用、遗失不补、违约不退、过期作废 -->
							</a-form-item>
							<a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="会计" :colon="false">
								<a-input placeholder="请输入姓名" v-model="invoiceInfo.accountant" :maxLength="10" />
							</a-form-item>
							<a-form-item :label-col="labelCol" :wrapperCol="{span: 4, offset: 20}" :colon="false">
								<a-button block type="primary" @click="showModal">保存</a-button>
							</a-form-item>
						</a-form>
					</a-card>
				</a-col>
				<a-col :span="1"></a-col>
				<a-col :span="13">
					<a-card title="打印预览" :bordered="false">
						<Invoice :invoiceInfo="invoiceInfo" />
					</a-card>
				</a-col>
			</a-row>
		</div>
	</div>
</template>

<script>
import {Modal} from 'ant-design-vue'
import Invoice from './invoice.vue'
import moment from 'moment'
import {setConfig, getConfig} from '@/api/personalization'
export default {
	name: 'setDownPayment',
	components: {Invoice},
	data() {
		return {
			loading: true,
			wrapperCol: {span: 20},
			labelCol: {span: 4},
			invoiceInfo: {
				name: '',
				notes: '',
				invoiceTime: moment().format('YYYY年MM月DD日'),
				diningDate: moment().format('YYYY年MM月DD日'),
				diningTime: moment().format('HH点mm分'),
				invoiceNo: 'XXXXXXX',
				orderType: '午餐',
				price: 100,
				remarks: '无',
				with_roomTitle: '预订餐位',
				accountant: '',
				rerson: '预订客户',
				salesName: '销售',
				paymentType: '微信'
			},
			form: {}
		}
	},
	async mounted() {
		this.fetchSetting()
	},
	computed: {},
	methods: {
		/**
		 * @description: 获取配置信息
		 * @return {*}
		 */
		async fetchSetting() {
			this.$store.dispatch('loading/startContentLoading')
			const {code, data = {}} = await getConfig()
			if (code === 0) {
				if (data.depositTemplate) {
					this.invoiceInfo = {
						...this.invoiceInfo,
						name: data.depositTemplate.name,
						notes: data.depositTemplate.notes,
						accountant: data.depositTemplate.accountant
					}
				}
			}
			this.$store.dispatch('loading/endContentLoading')
		},
		/**
		 * @description: 保存弹窗
		 * @return {*}
		 */
		showModal() {
			Modal.confirm({
				title: '提示',
				content: '确认保存定金模板吗？',
				onOk: async () => {
					await this.submitSetting()
				}
			})
		},
		/**
		 * @description: 保存表单内容
		 * @return {*}
		 */
		async submitSetting() {
			this.$message.loading({
				content: '正在保存中...',
				key: 'save',
				duration: 0
			})
			let {name, notes, accountant} = this.invoiceInfo
			// 转换字段
			const {code} = await setConfig({
				config_name: 'depositTemplate',
				config_value: {
					name: name || '',
					notes: notes || '',
					accountant: accountant || ''
				}
			})
			if (code === 0) {
				this.$message.success({content: '保存成功！', key: 'save'})
				this.fetchSetting()
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	width: 100%;
	max-width: 100%;
	border-radius: 4px;
}

.setDownPayment_box {
	padding: 10px;
}
</style>
